import { db } from '../firebase';
import firebase from 'firebase/app';

import { onLikedPost, offLikedPost } from '../common/firebase/post';

// xross interfaces
import { XROSS_POST } from '../common/interface';

// posts の全ドキュメントを取得する
export const getAllDocuments = async () => {
  try {
    const querySnapshot = await db.collection('posts').get(); // firebase.firestore.QuerySnapshotのインスタンスを取得
    console.log(querySnapshot.size);
    console.log(querySnapshot.empty);
    console.log(querySnapshot.docs.map((postDoc) => postDoc.id));
    querySnapshot.forEach((postDoc) => {
      console.log(postDoc.id, ' => ', JSON.stringify(postDoc.data()));
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

export const getPostAll = async () => {
  try {
    // firebase.firestore.QuerySnapshotのインスタンスを取得
    const snapshot = await db.collection('posts').get();
    const list = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST)
    );
    return list;
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
    return [];
  }
};

// posts 内の全ドキュメントを削除する
export const deleteAllDcocuments = (collection: string) => {
  console.log('==========');
  console.log('DELETE collection : ', collection);

  const docList: string[] = [];
  (async () => {
    let count = 0;
    try {
      const querySnapshot = await db.collection(collection).get(); // firebase.firestore.QuerySnapshotのインスタンスを取得

      querySnapshot.forEach((doc) => {
        console.log(doc.id, ' => ', JSON.stringify(doc.data()));
        docList.push(doc.id);
        console.log('>>>>> Listed docid = ' + doc.id);
        count++;
      });

      console.log('docList = ' + docList);
      docList.map((docid) => {
        db.collection(collection).doc(docid).delete();
        console.log('>>>>> deleted docid = ' + docid);
      });
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
    console.log('deleted collection document count = ' + count);
  })();
};

// xross.club utils
// setXrossUSerProfile
// uid を指定して、firebase の基本ユーザプロフィールを
// xrossUserProfile にセットする
export const setXrossUSerProfile = (user: firebase.User) => {
  console.log('>>xrossUserProfiile uid=' + user.uid);

  // xrossUserProfile にuid 情報があるか検査する
  const docRef = db.collection('xrossUser').doc(user.uid);
  docRef.get().then((doc) => {
    if (!doc.exists) {
      // uid なし
      // xrossUserProfile 初期化
      console.log('uidなし');
      docRef.set({
        xrossUserID: makeRandomChar(),
        xrossUserName: user.displayName,
        xrossAvatarURL: user.photoURL,
        xrossUserStatus: '_ACTIVE_',
        profile: '',
        homeGLocation: null,
        homeGLocationLabel: '',
        isDefaultLocationAtHome: false,
        defaultPostDisclosureRange: '_PUBLIC_',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      // uid あり
      console.log('uidあり');
    }
  });
};

export const makeOnLikedPost = async (postId: string) => {
  for (let id = 0; id < 100; id++) {
    await onLikedPost(String(id), postId);
  }
};

export const makeOffLikedPost = async (postId: string) => {
  for (let id = 0; id < 100; id++) {
    await offLikedPost(String(id), postId);
  }
};

//
// xross util
// ランダムな16文字を生成する
//
export const makeRandomChar = () => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  // ランダムな16文字を生成する
  const N = 16;
  const randomChar = Array.from(crypto.getRandomValues(new Uint32Array(N)))
    .map((n) => S[n % S.length])
    .join('');

  return randomChar;
};
