import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { Marker, InfoWindow } from '@react-google-maps/api';

// xross interfaces
import { XROSS_POST, XROSS_PUBLIC_USER_INFO } from '../common/interface';
import { useHistory } from 'react-router';
import { getPublicUserInfo } from '../common/firebase/user';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const PostMarker: React.FC<XROSS_POST> = (props) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const history = useHistory();

  //idから画像を取得
  const [PublicUserInfo, setPublicUserInfo] =
    useState<XROSS_PUBLIC_USER_INFO>();

  const [AvaterImage, SetAvaterImage] = useState<string>('');
  useEffect(() => {
    userNum();
  }, []);
  const userNum = async () => {
    const user = await getPublicUserInfo(props.uid);
    if (user != null) {
      SetAvaterImage(user.xrossAvatarURL);
    }
  };

  let postPosition = {
    lat: Number(props.gLocation.latitude),
    lng: Number(props.gLocation.longitude),
  };

  //画面遷移
  const onPress = (id: string) => {
    console.log('PostList ->' + id);
    history.push('/PostDetail/' + id);
  };

  return (
    <>
      <Marker
        position={postPosition}
        icon={{
          fillColor: '#FFFFFF', //塗り潰し色 cssで色指定と同じ
          fillOpacity: 1.0, //塗り潰し透過率
          path: google.maps.SymbolPath.CIRCLE, //円を指定
          scale: 38, //円のサイズ
          strokeColor: 'green', //枠の色
          strokeWeight: 3.0, //枠の太さ
        }}
      />
      <Marker
        position={postPosition}
        onClick={(event) => onPress(props.id)}
        label={{
          color: 'red',
          fontSize: '26px',
          text: props.uid,
        }}
        icon={{
          url: AvaterImage,
          anchor: new window.google.maps.Point(25, 25),
          scaledSize: new google.maps.Size(50, 50),
        }}
      />
    </>
  );
};

export default PostMarker;
