import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import GenericTemplate from "../components/templates/GenericTemplate";

import { RouteComponentProps } from "react-router-dom";

// xross interfaces
import { XROSS_USER_PROFILE } from "../common/interface";
// xross common function
import { getXrossUserProfile } from "../common/firebase/user";

// view compornent
import UserDetailView from "../components/UserDetailView";
import UserDetailEdit from "../components/UserDetailEdit";

type UserProps = RouteComponentProps<{
  id: string;
}>;

const UserDetail: React.FC<UserProps> = (props) => {
  console.log("----------");
  console.log(">>UserDetailView");

  // 引き渡されたuid
  const uid = props.match.params.id;

  // 拡張ユーザ情報を初期化する
  const [xrossUserProfile, setXrossUserProfile] = useState<XROSS_USER_PROFILE>({
    id: uid, // ドキュメントID(uid)
    xrossUserID: "",
    xrossUserName: "",
    xrossAvatarURL: "",
    xrossUserStatus: "",
    profile: "",
    homeGLocation: {
      latitude: "",
      longitude: "",
    },
    homeGLocationLabel: "",
    isDefaultLocationAtHome: false,
    defaultPostDisclosureRange: "",
    createdAt: null,
    lastRefreshAt: null,
  });

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const xrossUserProfile = await getXrossUserProfile(uid);
    if (xrossUserProfile) {
      setXrossUserProfile(xrossUserProfile);
    }
  };

  // true:view mode / false :edit mode
  const [viewMode, setViewMode] = useState(true);

  // view mode
  const changeViewMode = () => {
    viewMode ? setViewMode(false) : setViewMode(true);
  };

  return (
    <>
      <GenericTemplate title="xross.club ユーザ詳細画面">
        <Button variant="contained" color="primary" onClick={changeViewMode}>
          {viewMode ? "編集する！" : "表示モード"}
        </Button>

        {viewMode ? (
          <UserDetailView {...xrossUserProfile} />
        ) : (
          <UserDetailEdit {...xrossUserProfile} />
        )}
      </GenericTemplate>
    </>
  );
};

export default UserDetail;
