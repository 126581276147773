import React, { useState, useEffect } from "react";
import GenericTemplate from "../components/templates/GenericTemplate";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";

import { db } from "../firebase";

// interface
import { XROSS_FRIEND } from "../common/interface";

import {
  getFriendList,
  getFriendRiquestList,
  getFriendPendingList,
} from "../common/firebase/friend";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: "0px 8px",
  },
});

const UserList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  console.log("UserList");

  const [xrossUserList, setXrossUserList] = useState([
    {
      id: "", // ドキュメントID
      xrossUserID: "",
      xrossUserName: "",
      xrossAvatarURL: "",
      xrossUserStatus: "",
      profile: "",
      //homeGLocation: "",
      homeGLocationLabel: "",
      defaultLocationAtHome: "",
      defaultPostDisclosureRange: "",
      //createdAt: null,
      //lastRefreshAt: null,
    },
  ]);

  useEffect(() => {
    const unSub = db
      .collection("xrossUser")
      .orderBy("createdAt", "desc") // 新しいものが先頭になる
      .onSnapshot((snapshot) =>
        setXrossUserList(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            xrossUserID: doc.data().xrossUserID,
            xrossUserName: doc.data().xrossUserName,
            xrossAvatarURL: doc.data().xrossAvatarURL,
            xrossUserStatus: doc.data().xrossUserStatus,
            profile: doc.data().profile,
            //            homeGLocation: doc.data().homeGLocation,
            homeGLocationLabel: doc.data().homeGLocationLabel,
            defaultLocationAtHome: doc.data().defaultLocationAtHome,
            defaultPostDisclosureRange: doc.data().defaultPostDisclosureRange,
            //  createdAt: doc.data().createdAt,
            //lastRefreshAt: doc.data().lastRefreshAt,
          }))
        )
      );
    return () => {
      unSub(); // cleanup
    };
  }, []); // 第2引数がからなので最初に１回だけ実行される

  const [friendUser, setFriendUser] = useState<XROSS_FRIEND[]>([]);

  /*  useEffect(() => {
   getFirebaseItems();
 }, []);
 */
  const friend = async (uid: string) => {
    // uid = "Fc3jSJLYXiVCVQdBGKwEuYpampe2";
    const users = await getFriendList(uid);
    setFriendUser(users);
    return users.length;
  };

  const handleClick = (id: string) => {
    console.log("push:" + id);
    // history.push("/UserDetailView/" + id);
    history.push("/UserDetail/" + id);
  };

  return (
    <GenericTemplate title="ユーザリスト">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>uid</TableCell>
              <TableCell>xross ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Profile</TableCell>
              {/*   <TableCell>Home Location</TableCell> */}
              <TableCell>Home Location Name</TableCell>
              {/* <TableCell>規定の位置</TableCell> */}
              <TableCell>既定の位置</TableCell>
              <TableCell>既定の公開範囲</TableCell>
              {/*     <TableCell>作成日時</TableCell>
              <TableCell>更新日時</TableCell> */}
              <TableCell>友達</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {xrossUserList.map((xrossUser) => (
              <TableRow
                key={xrossUser.id}
                hover
                onClick={(event) => handleClick(xrossUser.id)}
              >
                <TableCell>{xrossUser.id}</TableCell>
                <TableCell>{xrossUser.xrossUserID}</TableCell>
                <TableCell>{xrossUser.xrossUserName}</TableCell>
                <TableCell>
                  <Avatar src={xrossUser.xrossAvatarURL} />
                </TableCell>
                <TableCell>{xrossUser.xrossUserStatus}</TableCell>
                <TableCell>{xrossUser.profile}</TableCell>
                {/* <TableCell>{xrossUser.homeGLocation}</TableCell> */}
                <TableCell>{xrossUser.homeGLocationLabel}</TableCell>
                <TableCell>
                  {xrossUser.defaultLocationAtHome ? "ホームを設定" : "GPS位置"}
                </TableCell>
                <TableCell>{xrossUser.defaultPostDisclosureRange}</TableCell>
                {/*    <TableCell>{xrossUser.createdAt}</TableCell>
                <TableCell>{xrossUser.lastRefreshAt}</TableCell> */}
                <TableCell>
                  <>
                    {() => {
                      friend(xrossUser.id);
                    }}
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </GenericTemplate>
  );
};
export default UserList;
