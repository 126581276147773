import React, { useEffect, useState } from "react";
import GenericTemplate from "../components/templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

//import { GetXrossServiceStatus } from "../common/GetXrossServiceStatus2";

// xross common function
import { getXrossServiceStatus } from "../common/firebase/status";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ServiceState: React.FC = () => {
  const classes = useStyles();
  const [xrossServiceState, setXrossServiceState] = useState({
    id: "", // ドキュメントID
    isOutOfService: false,
    isNewUserUnavailable: false,
    isNewPostUnavailable: false,
    createdAt: null,
    lastRefreshAt: null,
  });

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const status = await getXrossServiceStatus();
    if (status) {
      setXrossServiceState(status);
    }
  };

  return (
    <GenericTemplate title="xross.club サービス状態">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>システム状態</TableCell>
              <TableCell>新規ユーザ登録状態</TableCell>
              <TableCell>新規投稿状態</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key={xrossServiceState.id}>
              <TableCell component="th" scope="row">
                {xrossServiceState.isOutOfService ? "停止中" : "稼働中"}
              </TableCell>
              <TableCell>
                {xrossServiceState.isNewUserUnavailable ? "停止中" : "稼働中"}
              </TableCell>
              <TableCell>
                {xrossServiceState.isNewPostUnavailable ? "停止中" : "稼働中"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GenericTemplate>
  );
};
export default ServiceState;
