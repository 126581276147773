import { db } from "../../firebase";
import firebase from "firebase/app";

// xross interface
import { XROSS_FRIEND } from "../interface";

// コレクション名定義
import {
  CL_USER,
  SCL_FRIEND,
  SCL_FRIEND_REQUEST,
  SCL_FRIEND_PENDING,
} from "../define";

//
// 友達ユーザリストを取得する
//
export const getFriendList = async (uid: string) => {
  console.log("getFriendList>> called " + uid);

  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_FRIEND)
      .get();

    const users = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_FRIEND)
    );
    console.log("友達の数 = ", snapshot.size);
    console.log("友達リスト = ", users);
    return users;
  } catch (err) {
    console.log("getFriendList>> ", err);
    return [];
  }
};

//
// 友達申請中ユーザリストを取得する（依頼）
//
export const getFriendRiquestList = async (uid: string) => {
  console.log("getFriendRiquestList>> called " + uid);
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_FRIEND_REQUEST)
      .get();
    const users = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_FRIEND)
    );
    console.log("友達申請中の数 = ", snapshot.size);
    console.log("友達申請中ユーザ = ", users);
    return users;
  } catch (err) {
    console.log("getFriendRiquestList>> ", err);
    return [];
  }
};

//
// 友達申請中ユーザリストを取得する（受付）
//
export const getFriendPendingList = async (uid: string) => {
  console.log("getFriendPendingList>> called " + uid);
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_FRIEND_PENDING)
      .get();
    const users = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_FRIEND)
    );
    console.log("友達申請受付中の数 = ", snapshot.size);
    console.log("友達申請受付中のユーザ = ", users);
    return users;
  } catch (err) {
    console.log("getFriendPendingList>> ", err);
    return [];
  }
};

//
// 友達申請する
//
export const requestFriendTo = async (fromUid: string, ToUid: string) => {
  console.log(
    "requestFriendTo>> called from-uid = " + fromUid,
    " ToUid = ",
    ToUid
  );
  // （自分）友達申請したユーザのコレクション更新
  await db
    .collection(CL_USER)
    .doc(fromUid)
    .collection(SCL_FRIEND_REQUEST)
    .doc(ToUid)
    .set({
      uid: ToUid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  // （相手）友達申請を受けたユーザのコレクション更新
  await db
    .collection(CL_USER)
    .doc(ToUid)
    .collection(SCL_FRIEND_PENDING)
    .doc(fromUid)
    .set({
      uid: fromUid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

//
// 友達申請をリジェクトする
//
export const rejectFriendRequest = async (uid: string, target: string) => {
  console.log("rejectFriendTo>> called uid = " + uid, " target = ", target);

  // 友達申請を受けたユーザのコレクション更新
  const docRef = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_FRIEND_PENDING)
    .doc(target);

  await docRef
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};

//
// 友達申請をキャンセルする
//
export const cancelFriendRequest = async (uid: string, target: string) => {
  console.log(
    "cancelFriendRequest>> called uid = " + uid,
    " target = ",
    target
  );

  // （自分）友達申請したユーザのコレクション更新（ドキュメント削除）
  const fromUserDoc = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_FRIEND_REQUEST)
    .doc(target);

  await fromUserDoc
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  // （相手）友達申請を受けたユーザのコレクション更新（ドキュメント削除）
  const ToUserDoc = db
    .collection(CL_USER)
    .doc(target)
    .collection(SCL_FRIEND_PENDING)
    .doc(uid);

  await ToUserDoc.delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};

//
// 友達申請を許可する
//
export const applyFriendRequest = async (uid: string, target: string) => {
  console.log("applyriendRequest>> called uid = " + uid, " target = ", target);

  // 自分への友達申請に情報があるかどうか検査する
  const myPendingDocRef = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_FRIEND_PENDING)
    .doc(target);

  const getDoc = await myPendingDocRef.get();

  // 自分宛てに申請が残っている場合のみ友達になれる
  if (getDoc.exists) {
    // （自分）友達申請を受けたユーザのコレクション（pending）を消す
    await myPendingDocRef
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    // （相手）友達申請を受けたユーザのコレクション(request)を消す
    const targetRequestDocRef = db
      .collection(CL_USER)
      .doc(target)
      .collection(SCL_FRIEND_REQUEST)
      .doc(uid);

    await targetRequestDocRef
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    // （自分）友達申請コレクション更新
    await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_FRIEND)
      .doc(target)
      .set({
        uid: target,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

    // （相手）友達申請コレクション更新
    await db
      .collection(CL_USER)
      .doc(target)
      .collection(SCL_FRIEND)
      .doc(uid)
      .set({
        uid: uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  } else {
    console.log("残念！友達にはなれませんでした！");
  }
};

//
// 友達関係を破棄する
//
export const brokeFriendTo = async (uid: string, target: string) => {
  console.log("brokeFriend>> called uid = " + uid, " target = ", target);

  // （自分）友達コレクション更新（ドキュメント削除）
  const fromUserDoc = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_FRIEND)
    .doc(target);

  await fromUserDoc
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  // （相手）友達コレクション更新（ドキュメント削除）
  const ToUserDoc = db
    .collection(CL_USER)
    .doc(target)
    .collection(SCL_FRIEND)
    .doc(uid);

  await ToUserDoc.delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};
