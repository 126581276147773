//
// xross.club App commmon Definition
//
// xross.club firastore defines
// ver 1.0.0
//

// コレクション名定義
export const CL_STATUS = 'serviceState';
export const CL_USER = 'xrossUser';
export const CL_POST = 'posts';
export const SCL_LIKED = 'liked';
export const SCL_COMMENT = 'comments';
export const SCL_FRIEND = 'friend';
export const SCL_FRIEND_REQUEST = 'friendRequest';
export const SCL_FRIEND_PENDING = 'friendPending';

//
// 2022-01-14 add / message
//
export const CL_MESSAGE = 'message';
export const SCL_GROUP_TALK_ROOM = 'groupTalkRoom';
export const SCL_TALK_ROOM = 'talkRoom';
export const SCL_HISTORY = 'history';
export const SSCL_TALK = 'talk';

//
// 2022-01-24 add / clip
//
export const SCL_CLIP = 'clip';
export const SCL_CLIP_FOLDER = 'clipFolder';

// 2022-02-03 / お知らせ
export const CL_INFO = 'information';
export const SCL_INFO_READ = 'infoRead';

// ユーザ状態
export const _ACTIVE_ = '_ACTIVE_'; // Active
export const _SUSPEND_ = '_SUSPEND_'; // アカウント停止
export const _UNSUBSCRIBE_ = '_UNSUBSCRIBE_'; // 退会

// 投稿の公開範囲
export const _PUBLIC_ = '_PUBLIC_'; // 一般公開
export const _BETWEEN_FRIENDS_ = '_BETWEEN_FRIENDS_'; // 友達のみ
export const _PERSONAL_ = '_PERSONAL_'; // 自分のみ

// 投稿状態名定義
export const _STORED_ = '_STORED_'; //投稿
export const _TEMPORARILY_ = '_TEMPORARILY_'; //一時保存
export const _DELETED_ = '_DELETED_'; //論理削除（非表示）

// 投稿の種類
export type PostCategory = '_NORMAL_' | '_PR_';
export const _NORMAL_ = '_NORMAL_'; // 一般投稿
export const _PR_ = '_PR_'; // 広告

// お知らせのカテゴリー
export const _INFO_ = 'information'; // 情報
export const _IMPORTANT_ = 'alert'; // 重要
export const _AD_ = 'ad'; // 広告

// デフォルト値
export const _DEFAULT_AVATAR_ =
  'https://firebasestorage.googleapis.com/v0/b/xross-db-test.appspot.com/o/lab.png?alt=media&token=62f41676-90c9-455e-994c-ee06245993b1';
