import { db } from "../../firebase";
import firebase from "firebase/app";

// xross interface
import { XROSS_USER_PROFILE, XROSS_PUBLIC_USER_INFO } from "../interface";

// コレクション名定義
import { CL_USER } from "../define";

// ユーザ状態
import { _ACTIVE_, _SUSPEND_, _UNSUBSCRIBE_ } from "../define";

// 投稿の公開範囲
import { _PUBLIC_, _BETWEEN_FRIENDS_, _PERSONAL_ } from "../define";

// デフォルト値
import { _DEFAULT_AVATAR_ } from "../define";
//
// ユーザ情報の初期値
//
export const initialUser: XROSS_USER_PROFILE = {
  id: "",
  xrossUserID: "（設定なし）",
  xrossUserName: "（設定なし）",
  xrossAvatarURL: _DEFAULT_AVATAR_,
  xrossUserStatus: _ACTIVE_,
  profile: "",
  homeGLocation: {
    latitude: "",
    longitude: "",
  },
  homeGLocationLabel: "",
  isDefaultLocationAtHome: true,
  defaultPostDisclosureRange: _BETWEEN_FRIENDS_,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
};

//
// firebase の uid から、xrossUserProfile を取得する
//
export const getXrossUserProfile = async (uid: string) => {
  console.log("getXrossUserProfile>> called");
  const docid = uid;
  try {
    const snapshot = await db.collection(CL_USER).doc(docid).get();
    const user = snapshot.data() as XROSS_USER_PROFILE;
    user.id = docid; // uid は、ドキュメントIDとして使用しているため、メンバーにセットする
    console.log("user = ", user);
    return user;
  } catch (err) {
    console.log("getXrossUserProfile>> ", err);
    return null;
  }
};

//
// firebase の uid から、公開ユーザ情報を取得する
//
export const getPublicUserInfo = async (uid: string) => {
  console.log("getPublicUserInfo>> called");
  const docid = uid;
  try {
    const snapshot = await db.collection(CL_USER).doc(docid).get();
    const user = snapshot.data() as XROSS_PUBLIC_USER_INFO;
    user.id = docid; // uid は、ドキュメントIDとして使用しているため、メンバーにセットする
    console.log("user = ", user);
    return user;
  } catch (err) {
    console.log("getPublicUserInfo>> ", err);
    return null;
  }
};

//
// ログイン時にユーザ情報がない場合にイニシャル情報を登録する
//
export const updateUser = async (uid: string, params: any) => {
  await db.collection(CL_USER).doc(uid).update(params);
};

//
// firebase の XrossUSerProfile を強制的に全更新する
//
export const updateXrossUserForce = async (
  xrossUserProfile: XROSS_USER_PROFILE
) => {
  try {
    const userRef = db.collection(CL_USER).doc(xrossUserProfile.id);
    console.log("updateXrossUserForce>> called uid = ", xrossUserProfile.id);

    await userRef.update({
      xrossUserID: xrossUserProfile.xrossUserID,
      xrossUserName: xrossUserProfile.xrossUserName,
      xrossAvatarURL: xrossUserProfile.xrossAvatarURL,
      xrossUserStatus: xrossUserProfile.xrossUserStatus,
      profile: xrossUserProfile.profile,
      homeGLocation: xrossUserProfile.homeGLocation,
      homeGLocationLabel: xrossUserProfile.homeGLocationLabel,
      isDefaultLocationAtHome: xrossUserProfile.isDefaultLocationAtHome,
      defaultPostDisclosureRange: xrossUserProfile.defaultPostDisclosureRange,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

//
//ユーザ登録数を取得する
//
export const getXrossUserNum = async () => {
  console.log("getXrossUserNum>> called");

  try {
    const snapshot = await db.collection("xrossUser").get();
    console.log(snapshot.size);
    console.log(snapshot.empty);
    console.log("user num = ", snapshot.size);
    return snapshot.size;
  } catch (err) {
    console.log("getXrossUserNum>> ", err);
    return 0;
  }
};
