import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
interface USER {
  displayName: string;
  photoUrl: string;
  xrossUserID: string;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      uid: "",
      photoUrl: "",
      displayName: "",
      xrossUserID: "",
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      // logout時に初期化する
      state.user = {
        uid: "",
        photoUrl: "",
        displayName: "",
        xrossUserID: "",
      };
    },
    updateUserProfile: (state, action: PayloadAction<USER>) => {
      state.user.displayName = action.payload.displayName;
      state.user.photoUrl = action.payload.photoUrl;
      state.user.xrossUserID = action.payload.xrossUserID;
    },
  },
});

export const { login, logout, updateUserProfile } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export default userSlice.reducer;
