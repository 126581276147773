import React, { useState, useEffect } from "react";
//import { Avatar } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";

// View
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// xross interfaces
import { XROSS_USER_PROFILE } from "../common/interface";
import { XROSS_FRIEND } from "../common/interface";

import {
  getFriendList,
  getFriendRiquestList,
  getFriendPendingList,
} from "../common/firebase/friend";

const UserDetailView: React.FC<XROSS_USER_PROFILE> = (xrossUserProfile) => {
  console.log("xrossUserID=" + xrossUserProfile.xrossUserID);
  const [friendUser, setFriendUser] = useState<XROSS_FRIEND[]>([]);
  const [friendRequestUser, setFriendRequestUser] = useState<XROSS_FRIEND[]>(
    []
  );
  const [friendPendingUser, setFriendPendingUser] = useState<XROSS_FRIEND[]>(
    []
  );

  useEffect(() => {
    friendNum();
  }, []);

  const friendNum = async () => {
    const uid = xrossUserProfile.id;
    const friend = await getFriendList(uid);
    const request = await getFriendRiquestList(uid);
    const pending = await getFriendPendingList(uid);
    setFriendUser(friend);
    setFriendRequestUser(request);
    setFriendPendingUser(pending);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <Avatar
              sx={{ width: 56, height: 56 }}
              src={xrossUserProfile.xrossAvatarURL}
            />
          </TableRow>

          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>uid</TableCell>
            <TableCell>{xrossUserProfile.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>xrossUserID</TableCell>
            <TableCell>{xrossUserProfile.xrossUserID}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>xrossUserName</TableCell>
            <TableCell>{xrossUserProfile.xrossUserName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>xrossAvatarURL</TableCell>
            <TableCell>{xrossUserProfile.xrossAvatarURL}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>xrossUserStatus</TableCell>
            <TableCell>{xrossUserProfile.xrossUserStatus}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>profile</TableCell>
            <TableCell>{xrossUserProfile.profile}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>homeGLocation</TableCell>
            <TableCell>
              {xrossUserProfile.homeGLocation && (
                <div>
                  <p>{xrossUserProfile.homeGLocation.latitude}</p>
                  <p>{xrossUserProfile.homeGLocation.longitude}</p>
                </div>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>homeGLocationLabel</TableCell>
            <TableCell>
              {xrossUserProfile.homeGLocationLabel && (
                <div>{xrossUserProfile.homeGLocationLabel}</div>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>isDefaultLocationAtHome</TableCell>
            <TableCell>
              {xrossUserProfile.isDefaultLocationAtHome ? "true" : "false"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>defaultPostDisclosureRange</TableCell>
            <TableCell>{xrossUserProfile.defaultPostDisclosureRange}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>createdAt</TableCell>
            <TableCell>
              {new Date(xrossUserProfile.createdAt?.toDate()).toLocaleString()}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>lastRefreshAt</TableCell>
            <TableCell>
              {new Date(
                xrossUserProfile.lastRefreshAt?.toDate()
              ).toLocaleString()}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>友達</TableCell>
            <TableCell>
              {friendUser.length}人
              {friendUser.map((doc, i) => (
                <li key={i}>{doc.id}</li>
              ))}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>友達申請中</TableCell>
            <TableCell>
              {friendRequestUser.length}人
              {friendRequestUser.map((doc, i) => (
                <li key={i}>{doc.id}</li>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>友達申請受付中</TableCell>
            <TableCell>
              {friendPendingUser.length}人
              {friendPendingUser.map((doc, i) => (
                <li key={i}>{doc.id}</li>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserDetailView;
