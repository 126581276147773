import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import { XROSS_INFORMATION, XROSS_INFO_READ } from '../../common/interface';

// コレクション名定義
import { CL_INFO, SCL_INFO_READ } from '../../common/define';

// 投稿状態名定義
import { _STORED_, _TEMPORARILY_, _DELETED_ } from '../../common/define';

//
// お知らせの投稿
//
export const createInformation = async (newInfo: XROSS_INFORMATION) => {
  console.log('createPost ', newInfo);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  try {
    await db.collection(CL_INFO).add({
      ...newInfo,
      createdAt: timestamp,
      lastRefreshAt: timestamp,
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

//
// お知らせ投稿の編集
//
export const editInformation = async (
  infoId: string,
  newInfo: XROSS_INFORMATION
) => {
  console.log('editInformation ', infoId, ' ', newInfo);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  try {
    await db
      .collection(CL_INFO)
      .doc(infoId)
      .update({
        ...newInfo,
        lastRefreshAt: timestamp,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

// 全員へのお知らせを取得する
export const getInformationList = async (
  uid: string
): Promise<XROSS_INFORMATION[]> => {
  console.log('getInformationList called :', uid);
  const info = await db
    .collection(CL_INFO)
    .where('status', '==', _STORED_)
    .orderBy('createdAt', 'desc')
    .limit(10)
    .get();
  const infoList = info.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as XROSS_INFORMATION)
  );
  // 情報のフィルタリング
  // ALL or 自分宛て
  console.log('uid ', uid);
  console.log('getInformationList 結果 ', infoList.length);
  return infoList;
};
