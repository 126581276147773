//
// xross.club App commmon method

// collection関連
// 2022-01-24

// ver 1.0.0

import { db } from '../../firebase';
import firebase from 'firebase/app';

import { getPostDetail } from './post';
// xross interface
import { XROSS_CLIP, XROSS_CLIP_FOLDER } from '../interface';

// コレクション名定義
import { CL_USER, SCL_CLIP, SCL_CLIP_FOLDER } from '../define';

/*
 *(1)  投稿記事を保存する
 */
export const addClip = async (uid: string, postId: string) => {
  try {
    console.log('addClip ', postId);
    await db.collection(CL_USER).doc(uid).collection(SCL_CLIP).doc(postId).set({
      uid: uid,
      postId: postId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(2)  投稿記事を保存から削除する
 */
export const removeClip = async (uid: string, postId: string) => {
  try {
    const ref = db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP)
      .doc(postId);

    console.log('removeClip ', postId);
    await ref.delete();
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(3)  保存フォルダーを作成する
 */
export const makeClipFolder = async (uid: string, folderName: string) => {
  try {
    const ref = db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .doc();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    console.log('makeClipFolder ', folderName);
    await ref.set({
      uid: uid,
      folderName: folderName,
      clipList: [],
      createdAt: timestamp,
      lastRefreshAt: timestamp,
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(4)  保存フォルダーを削除する
 */
export const removeClipFolder = async (uid: string, folderId: string) => {
  try {
    const ref = db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .doc(folderId);
    console.log('removeClipFolder ', folderId);
    await ref.delete();
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(5)  保存した投稿記事をフォルダに入れる
 */
export const addClipToFolder = async (
  uid: string,
  postId: string,
  folderId: string
) => {
  try {
    const ref = db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .doc(folderId);
    console.log('addClipToFolder folderId=', folderId, ' postId=', postId);
    await ref.update({
      clipList: firebase.firestore.FieldValue.arrayUnion(postId),
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(6)  フォルダに保存した投稿記事を削除する
 */
export const removeClipFromFrolder = async (
  uid: string,
  postId: string,
  folderId: string
) => {
  try {
    const ref = db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .doc(folderId);
    console.log(
      'removeClipFromFrolder folderId=',
      folderId,
      ' postId=',
      postId
    );
    await ref.update({
      clipList: firebase.firestore.FieldValue.arrayRemove(postId),
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 *(7)  保存した投稿記事リストを取得する
 */
export const getClipList = async (uid: string) => {
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP)
      .get();

    console.log('getClipList=', uid);
    const clipList = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_CLIP)
    );
    return clipList;
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
    return [];
  }
};

/*
 *(8)  保存したフォルダリストを取得する
 */
export const getClipFolderList = async (uid: string) => {
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .get();

    console.log('getClipFolderList=', uid);
    const clipFolderList = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_CLIP_FOLDER)
    );
    return clipFolderList;
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
    return [];
  }
};

/*
 *(8)-1  保存フォルダ内にあるpostIdが CLIP 内に存在するか検証する
 *       あれば、Post内容 を返却し、無ければ null を返却する
 */
export const hasCollection = async (uid: string, postId: string) => {
  const ref = await db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_CLIP)
    .doc(postId)
    .get();
  console.log('hasCollection ', ref);
  if (ref.exists) {
    console.log('hasCollection ', ref, ' あるよ ');
    const post = await getPostDetail(postId);
    return post;
  } else {
    return null;
  }
};

/*
 *(9)  フォルダ情報を取得する（フォルダ情報の中に保存したクリップのリファレンスがある）
 *     (8)で代用できる。。。
 */
export const getClipFolderInfo = async (uid: string, folderId: string) => {
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_CLIP_FOLDER)
      .doc(folderId)
      .get();

    console.log('getClipListByFolder=', folderId);
    const foldrInfo = snapshot.data() as XROSS_CLIP_FOLDER;
    return foldrInfo;
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
    return null;
  }
};
