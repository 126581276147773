import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import PostList from '../components/PostList';

import GenericTemplate from '../components/templates/GenericTemplate';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// xross interfaces
import { XROSS_POST } from '../common/interface';

// Admin用関数群
import { getPostAll } from '../adminUtil/adminXrossUtil';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// xross.club 運営管理画面
// 投稿一覧を表示する
// 表示コンポーネントは、PostList.tsx を使用する。
const PostCollection: React.FC = () => {
  console.log('PostCollection');

  const classes = useStyles();
  const [posts, setPosts] = useState<XROSS_POST[]>([]);

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const postList = await getPostAll();
    console.log('getPostAll ', postList);
    if (postList) {
      setPosts(postList);
    }
  };

  return (
    <GenericTemplate title="投稿一覧">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>投稿ID</TableCell>
              <TableCell>投稿日時</TableCell>
              {/*   <TableCell>アバター</TableCell>
              <TableCell>ユーザ名</TableCell> */}
              <TableCell>uid</TableCell>
              <TableCell>メッセージ</TableCell>
              <TableCell>イメージ</TableCell>
            </TableRow>
          </TableHead>

          <>
            {posts.map((post) => (
              <PostList
                key={post.id}
                id={post.id}
                createdAt={post.createdAt}
                uid={post.uid}
                text={post.text}
                hashtag={post.hashtag}
                contents={post.contents}
                gLocation={post.gLocation}
                displayGLocation={post.displayGLocation}
                displayGLocationLabel={post.displayGLocationLabel}
                lastRefreshAt={post.lastRefreshAt}
                disclosureRange={post.disclosureRange}
                postStatus={post.postStatus}
                category={post.category}
              />
            ))}
          </>
        </Table>
      </TableContainer>
    </GenericTemplate>
  );
};
export default PostCollection;
