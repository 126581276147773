import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './ShowCarousel.module.css';

interface PROPS {
  imageUrl: string[];
}

const ShowMCarousel: React.FC<PROPS> = (props) => {
  let calrouselMode = false;
  if (props.imageUrl.length !== 1) {
    calrouselMode = true;
  }

  return (
    <div>
      {calrouselMode ? (
        <Carousel>
          {props.imageUrl.map((url) => (
            <div className={styles.post_tweetImage}>
              <img src={url} />
            </div>
          ))}
        </Carousel>
      ) : (
        <div className={styles.post_tweetImage}>
          <img src={props.imageUrl[0]} />
        </div>
      )}
    </div>
  );
};

export default ShowMCarousel;
