import React from "react";
import GenericTemplate from "../components/templates/GenericTemplate";

const HomePage: React.FC = () => {
  return (
    <GenericTemplate title="ダッシュボード">
      <>xross.club サービス　ダッシュボード</>
    </GenericTemplate>
  );
};

export default HomePage;
