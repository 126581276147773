import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import { XROSS_POST_EX, XROSS_COMMENT, XROSS_LIKED } from '../interface';

// コレクション名定義
import { CL_USER } from '../define';
import { CL_POST, SCL_LIKED, SCL_COMMENT } from '../define';

// 投稿状態名定義
import { _STORED_, _TEMPORARILY_, _DELETED_ } from '../define';

//
// 新規投稿
//
export const createPost = async (postDataSet: XROSS_POST_EX) => {
  console.log('createPost ', postDataSet);
  // uid から（自分の）ユーザ情報を取得する
  /*   const uid = postDataSet.uid;
  const userInfo = await getXrossUserProfile(uid);

  if (userInfo != null) {
    if (
      postDataSet.disclosureRange === "" ||
      postDataSet.disclosureRange == null
    ) {
      postDataSet.disclosureRange = userInfo.defaultPostDisclosureRange;
    }
  }

  if (postDataSet.postStatus === "" || postDataSet.postStatus == null) {
    postDataSet.postStatus = _STORED_;
  }
 */
  try {
    const userRef = db.collection(CL_POST).add({
      uid: postDataSet.uid,
      text: postDataSet.text,
      hashtag: postDataSet.hashtag,
      contents: postDataSet.contents,
      gLocation: postDataSet.gLocation,
      displayGLocation: postDataSet.displayGLocation,
      displayGLocationLabel: postDataSet.displayGLocationLabel,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
      disclosureRange: postDataSet.disclosureRange,
      postStatus: postDataSet.postStatus,
      category: postDataSet.category,
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

//
// postId から、返信コメントリスト を取得する
//
export const getCommentList = async (postId: string) => {
  console.log('getCommentList>> called postId = ', postId);

  try {
    const snapshot = await db
      .collection(CL_POST)
      .doc(postId)
      .collection(SCL_COMMENT)
      .orderBy('createdAt', 'desc')
      .get();
    const commentList = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_COMMENT)
    );
    console.log('返信コメント = ', commentList);
    return commentList;
  } catch (err) {
    console.log('getCommentList>> ', err);
    return [];
  }
};

//
// postId から、いいねしたユーザリスト を取得する
//
export const getLikedUserList = async (postId: string) => {
  console.log('getLikedUserList>> called ' + postId);
  try {
    const snapshot = await db
      .collection(CL_POST)
      .doc(postId)
      .collection(SCL_LIKED)
      .get();
    const likedUserList = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_LIKED)
    );
    console.log('いいねの数 = ', snapshot.size);
    console.log('いいねしたユーザ = ', likedUserList);
    return likedUserList;
  } catch (err) {
    console.log('getLikedUserList>> ', err);
    return [];
  }
};

//
//投稿数を取得する
//
export const getXrossPostNum = async () => {
  console.log('getXrossPostNum>> called');

  try {
    const snapshot = await db.collection('posts').get();
    console.log(snapshot.size);
    console.log(snapshot.empty);
    console.log('post num = ', snapshot.size);
    return snapshot.size;
  } catch (err) {
    console.log('getXrossPostNum>> ', err);
    return 0;
  }
};

//
// 投稿内容を取得する
//
export const getPostDetail = async (postId: string) => {
  console.log('getPostDetail>> called');
  try {
    const snapshot = await db.collection(CL_POST).doc(postId).get();
    const postDoc = snapshot.data() as XROSS_POST_EX;
    postDoc.id = postId;
    return postDoc;
  } catch (err) {
    console.log('getPostDetail>> ', err);
    return null;
  }
};

//
// 「いいね」する
//
export const onLikedPost = async (uid: string, postId: string) => {
  console.log('onLikedPost>> called uid = ' + uid, ' postId = ', postId);

  await db.collection(CL_POST).doc(postId).collection(SCL_LIKED).doc(uid).set({
    uid: uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  await db.collection(CL_USER).doc(uid).collection(SCL_LIKED).doc(postId).set({
    postId: postId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

//
// 「いいね」を外す
//
export const offLikedPost = async (uid: string, postId: string) => {
  console.log('onLikedPost>> called uid = ' + uid, ' postId = ', postId);

  const postRef = db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_LIKED)
    .doc(uid);

  await postRef
    .delete()
    .then(() => {
      console.log('Document successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing document: ', error);
    });

  const userRef = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_LIKED)
    .doc(postId);

  await userRef
    .delete()
    .then(() => {
      console.log('Document successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing document: ', error);
    });
};

//
// 投稿に対してコメントする
//
export const putComment = async (
  uid: string,
  postId: string,
  comment: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db.collection(CL_POST).doc(postId).collection(SCL_COMMENT).add({
    uid: uid,
    comment: comment,
    status: _STORED_,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

//
// 返信コメントを修正する
//
export const updateComment = async (
  uid: string,
  postId: string,
  commentId: string,
  comment: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_COMMENT)
    .doc(commentId)
    .set({
      uid: uid,
      comment: comment,
      status: _STORED_,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

//
// 返信コメントを削除する
//
export const deleteComment = async (
  uid: string,
  postId: string,
  commentId: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_COMMENT)
    .doc(commentId)
    .set({
      status: _DELETED_,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};
