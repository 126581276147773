export const locationSample = [
  ["1", "秋葉原", "35.69845243024748", "139.77160637861942"],
  ["2", "岩本町", "35.69573396536447", "139.77443879125482"],
  ["3", "お茶の水", "35.700334390109326", "139.76426785497304"],
  ["4", "浅草橋", "35.697406877798066", "139.78473847356548"],
  ["5", "末広町", "35.70287845042237", "139.77134888656167"],
  ["6", "御徒町", "35.706816356300465", "139.77503960605628"],
  ["7", "akibaヨドバシ", "35.69942826683159", "139.77439587591186"],
  ["8", "神田", "35.69193492971619", "139.76997559558689"],
  ["9", "UDX", "35.70092684976184", "139.7724646854786"],
  ["10", "日本大学", "35.69834787561945", "139.76461117771674"],
  ["11", "湯島", "35.70726937724371", "139.76954644215726"],
  ["12", "上野広小路", "35.70786178536178", "139.77276509287935"],
  ["13", "神保町", "35.69604763911899", "139.75774472284297"],
  ["14", "東京都立忍岡高校", "35.70155415518382", "139.78302185984705"],
  ["15", "稲荷町", "35.71141614161373", "139.78233521435968"],
  ["16", "田原町", "35.71005714176225", "139.79031746815042"],
  ["17", "上野の森美術館", "35.713193260302084", "139.77431004522597"],
  ["18", "うさぎや", "35.70646787689991", "139.77160637861942"],
  ["19", "九段下", "35.69580367074989", "139.75070660659736"],
  ["20", "馬喰町", "35.69402616439001", "139.7824210450456"],
  ["21", "蔵前", "35.70406332751678", "139.79147618241038"],
  ["22", "浅草", "35.71099799028129", "139.79658310822276"],
  ["23", "京成上野", "35.711172220269745", "139.77366631508156"],
  ["24", "小伝馬町", "35.69074987239485", "139.77795784937766"],
  ["25", "三井記念病院", "35.69995974423347", "139.77939551336686"],
  ["26", "パリス", "35.69932371348308", "139.781412534486"],
  ["27", "UDX郵便局", "35.6999292497245", "139.77250223626785"],
  ["28", "スタジオ秋葉原", "35.69900569620561", "139.76979856966133"],
  ["29", "神田郵便局", "35.69756371151743", "139.76885443207524"],
  ["30", "マンガアート", "35.69780767450204", "139.77197115885778"],
  ["31", "神田万世橋", "35.69689280946136", "139.76997023099224"],
  ["32", "デジタルハリウッド", "35.69873124190565", "139.76634388443765"],
  ["33", "昌平橋", "35.698378370720434", "139.76887052525447"],
  ["34", "かんだやぶそば", "35.69709320937225", "139.7687042282962"],
  ["35", "日本大学歯学部", "35.69822690305487", "139.76441941591736"],
  ["36", "明治大学", "35.69742531180277", "139.76152263041652"],
  ["37", "三楽病院", "35.700117580938006", "139.76117930769053"],
  [
    "38",
    "ニッポンレンタカー秋葉原営業所",
    "35.6991678911682",
    "139.76877532319378",
  ],
  ["39", "明神会館", "35.70179985122245", "139.76815088688326"],
  ["40", "万世橋警察", "35.697367303238806", "139.77167842614074"],
  ["41", "順天堂大学", "35.70265681957944", "139.76079644577888"],
  ["42", "ホテル東京ガーデンパレス", "35.70299872763851", "139.76489202287127"],
  ["43", "湯島聖堂", "35.70092653509821", "139.76637204449966"],
  ["44", "東京文京病院", "35.704314541943646", "139.76774999567095"],
  ["45", "浅草郵便局", "35.70004452143353", "139.78075453893607"],
  ["46", "凸版印刷", "35.70054185805645", "139.77815174227922"],
  ["47", "青島食堂", "35.69759923789777", "139.77894278857275"],
  ["48", "三井ガーデンホテル大手町", "35.68939248400794", "139.76697502702442"],
  ["49", "三越前", "35.687195583375555", "139.77339270693676"],
  [
    "50",
    "自衛隊東京大規模接種センター",
    "35.68938212136197",
    "139.76294324370454",
  ],
];
