import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GenericTemplate from '../components/templates/GenericTemplate';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TextField, Grid, Modal, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
// xross web page styles
import { xrossUseStyles } from '../components/pageStyles';

import { getInformationList } from '../common/firebase/info';
import { XROSS_INFORMATION, XROSS_INFO_READ } from '../common/interface';
import { _INFO_, _IMPORTANT_, _AD_ } from '../common/define';
import { _STORED_, _TEMPORARILY_, _DELETED_ } from '../common/define';
import { createInformation } from '../common/firebase/info';

// icon例
// https://materialdesignicons.com/

enum INFO_SCREEN {
  INFO_LIST = 'お知らせ一覧',
  INFO_DETAIL = 'お知らせ詳細',
}

interface INFO_LIST extends XROSS_INFORMATION {
  isRead: boolean;
  icon: string;
  date: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '0px 8px',
  },
});

// モーダルカードの表示関数
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// xross.club 運営管理画面
// お知らせを表示する
const InfoPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false); //初期値はモーダルが閉じている状態
  const [info, setInfo] = useState<INFO_LIST[]>([]);
  const [detail, setDetail] = useState<INFO_LIST>();
  const classes = xrossUseStyles();

  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [reload, setReload] = useState('');
  // お知らせを取得する
  useEffect(() => {
    getInfo();
  }, [reload]);

  //
  //
  //
  const uid = '0';

  const getInfo = async () => {
    const ed: INFO_LIST[] = [];
    const list = await getInformationList(uid);
    console.log('list=', list);
    for (let i = 0; i < list.length; i++) {
      // icon 設定
      let icon = _INFO_;
      if (list[i].category === _INFO_) {
        icon = 'information';
      } else if (list[i].category === _IMPORTANT_) {
        icon = 'alert';
      }
      console.log('icon=', icon);

      // 日時設定
      const datetime = new Date(list[i].createdAt?.toDate()).toLocaleString();

      ed.push({
        ...list[i],
        isRead: false,
        icon: icon,
        date: datetime,
      });
    }
    setInfo(ed);
  };

  const handleClick = (id: string) => {
    console.log('push:' + id);
  };

  const onClicHandler = async () => {
    const newInfo: XROSS_INFORMATION = {
      id: '', //  ドキュメントID
      createdAt: null,
      lastRefreshAt: null,
      category: '_INFO_',
      title,
      text,
      status: _STORED_, // 投稿状態
      messageTo: [],
    };
    await createInformation(newInfo);
    setReload(Math.random().toString(36).slice(-8));
    setOpenModal(false);
  };

  return (
    <GenericTemplate title="お知らせ">
      <TableContainer component={Paper}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenModal(true)}
        >
          {'新しいインフォメーションを投稿する'}
        </Button>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/*  <TableCell>id</TableCell> */}
              <TableCell>date</TableCell>
              {/*       <TableCell>lastRefreshAt</TableCell> */}
              <TableCell>category</TableCell>
              <TableCell>title</TableCell>
              <TableCell>text</TableCell>
              <TableCell>status</TableCell>
              <TableCell>messageTo</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {info.map((list) => (
              <TableRow
                key={list.id}
                hover
                onClick={(event) => handleClick(list.id)}
              >
                {/*    <TableCell>{list.id}</TableCell> */}
                <TableCell>{list.date}</TableCell>
                {/*   <TableCell>{list.lastRefreshAt}</TableCell> */}
                <TableCell>{list.category}</TableCell>
                <TableCell>{list.title}</TableCell>
                <TableCell>{list.text}</TableCell>
                <TableCell>{list.status}</TableCell>
                <TableCell>{list.messageTo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div style={getModalStyle()} className={classes.modal}>
          <div>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              name="title"
              label="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              name="text"
              label="text"
              value={text}
              multiline
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setText(e.target.value);
              }}
            />
            <IconButton onClick={onClicHandler}>
              <SendIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    </GenericTemplate>
  );
};
export default InfoPage;
