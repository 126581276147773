import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
//import GoogleMapReact from "google-map-react";
//import { Map, Marker } from "google-maps-react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import mapStyles from '../components/MAP/mapStyles/Shades_of_Grey';
import GenericTemplate from '../components/templates/GenericTemplate';
//import { makeStyles } from '@material-ui/core/styles';

import PostMarker from '../components/PostMarker';

// xross interfaces
import { XROSS_POST } from '../common/interface';

/* const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
}); */
//const API_KEY = "AIzaSyB1hLSFmuu2w5FDHRsjLZ-db8J6ZAWS4xg";
// Google MAP　API　since 2021-10-21
const API_KEY = 'AIzaSyAe3ZPuxHh6gF5knxwMhE0ari72C7XJ8bU';

const options = {
  // style は、https://snazzymaps.com/　で作成
  styles: mapStyles,
  // デフォルトUI（衛星写真オプションなど）をキャンセルします。
  disableDefaultUI: true,
  zoomControl: true,
};

// xross.club 運営管理画面
// 投稿一覧を表示する
// 表示コンポーネントは、PostList.tsx を使用する。
const MapPage: React.FC = () => {
  const center = {
    lat: 35.69575,
    lng: 139.77521,
  };

  const containerStyle = {
    height: '100vh',
    width: '100%',
  };
  const [posts, setPosts] = useState<XROSS_POST[]>([]);

  useEffect(() => {
    const unSub = db
      .collection('posts')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) =>
        setPosts(
          snapshot.docs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST)
          )
        )
      );
    return () => {
      unSub(); // cleanup
    };
  }, []); // 第2引数がからなので最初に１回だけ実行される

  return (
    <GenericTemplate title="投稿一覧（地図）">
      {/*
      <>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          defaultCenter={center}
          defaultZoom={12}
        >
          <Marker position={{ lat: 35.652832, lng: 139.839478 }}></Marker>
        </GoogleMapReact>
      </> */}

      {/*    <div style={{ width: "100vw", height: "100vh" }}> */}
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
          options={options}
        >
          {posts[0]?.id && ( // 投稿が存在するときのみ
            <>
              {posts.map((doc) => (
                <PostMarker
                  key={doc.id}
                  id={doc.id}
                  uid={doc.uid}
                  text={doc.text}
                  hashtag={doc.hashtag}
                  contents={doc.contents}
                  gLocation={doc.gLocation}
                  displayGLocation={doc.displayGLocation}
                  displayGLocationLabel={doc.displayGLocationLabel}
                  createdAt={doc.createdAt}
                  lastRefreshAt={doc.lastRefreshAt}
                  disclosureRange={doc.disclosureRange}
                  postStatus={doc.postStatus}
                  category={doc.category}
                />
              ))}
            </>
          )}
        </GoogleMap>
      </LoadScript>
      {/*    </div> */}
    </GenericTemplate>
  );
};
export default MapPage;
