//
// xross.club App commmon method

// message関連
// 2022-01-23

// ver 1.0.0

import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import {
  XROSS_MESSAGE,
  XROSS_GROUP_TALK_ROOM,
  XROSS_TALK_ROOM,
  XROSS_TALK,
  XROSS_TALK_HISTORY,
} from '../interface';

// コレクション名定義
import {
  CL_MESSAGE,
  SCL_GROUP_TALK_ROOM,
  SCL_TALK_ROOM,
  SCL_HISTORY,
  SSCL_TALK,
} from '../define';
import { id } from 'date-fns/locale';

/*
 *(1)  メッセージルームを設定する
 */
export const setMessageRoom = async (
  uid: string,
  personUid: string,
  roomName?: string
) => {
  console.log(
    'setMessageRoom uid=',
    uid,
    ' personUid=',
    personUid,
    ' roomName=',
    roomName
  );

  // --------------------------------------------------------------
  // 作成日時
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // --------------------------------------------------------------
  // roomId : オーナID_相手ID
  const roomId = 'ROOM_ID-' + uid + '_' + personUid;
  // roomId : 相手ID_オーナID（相手が先に作っていた場合）
  const roomReverseId = 'ROOM_ID-' + personUid + '_' + uid;

  // もし既に相手側がトークルームを作っていたら作成しない
  //
  const ownerTalkRoomRef = db
    .collection(CL_MESSAGE)
    .doc(uid)
    .collection(SCL_TALK_ROOM)
    .doc(roomId);
  const ownerTalkRoomRef_revers = db
    .collection(CL_MESSAGE)
    .doc(uid)
    .collection(SCL_TALK_ROOM)
    .doc(roomReverseId);

  const snap1 = await ownerTalkRoomRef.get();
  const snap2 = await ownerTalkRoomRef_revers.get();
  let retRoomId = '';

  // RoomIDの判定
  if (snap1.exists) {
    retRoomId = roomId;
  } else {
    if (snap1.exists) {
      retRoomId = roomReverseId;
    }
  }
  // 新規作成の場合
  if (!snap1.exists && !snap2.exists) {
    retRoomId = roomId;
    // --------------------------------------------------------------
    //ユーザドキュメントを更新
    // owner
    const ownerRef = db.collection(CL_MESSAGE).doc(personUid);
    if ((await ownerRef.get()).exists) {
      await ownerRef.update({
        lastRefreshAt: timestamp,
      });
    } else {
      // ドキュメント作成
      await ownerRef.set({
        createdAt: timestamp,
        lastRefreshAt: timestamp,
      });
    }

    //ユーザドキュメントを更新
    // person
    const personRef = db.collection(CL_MESSAGE).doc(uid);
    if ((await personRef.get()).exists) {
      await personRef.update({
        lastRefreshAt: timestamp,
      });
    } else {
      // ドキュメント作成
      await personRef.set({
        createdAt: timestamp,
        lastRefreshAt: timestamp,
      });
    }

    // --------------------------------------------------------------
    // roomList
    // オーナ(uid) の roomList 配列に追加
    await db
      .collection(CL_MESSAGE)
      .doc(uid)
      .update({
        roomList: firebase.firestore.FieldValue.arrayUnion(roomId),
      });

    // 相手(personUid) の roomList 配列に追加
    await db
      .collection(CL_MESSAGE)
      .doc(personUid)
      .update({
        roomList: firebase.firestore.FieldValue.arrayUnion(roomId),
      });
    // --------------------------------------------------------------
    // ドキュメント新規作成
    // オーナのトークルームを作成
    await db
      .collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .set({
        roomId: roomId,
        roomName: personUid,
        person: personUid,
        createdAt: timestamp,
        lastRefreshAt: timestamp,
        deleteFlag: false,
      });
    // 相手のトークルームを作成
    await db
      .collection(CL_MESSAGE)
      .doc(personUid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .set({
        roomId: roomId,
        roomName: uid,
        person: uid,
        createdAt: timestamp,
        lastRefreshAt: timestamp,
        deleteFlag: false,
      });
  } else {
    // 既にどちらかが存在する
    // 何もしない
    //
  }
  return retRoomId;
};

/*
 * (2) ルーム名を設定する
 */
export const setMessageRoomName = async (
  uid: string,
  roomId: string,
  roomName: string
) => {
  console.log(
    'setMessageRoomName uid=',
    uid,
    ' roomId=',
    roomId,
    ' roomName=',
    roomName
  );
  // 自分が持っているルーム名のみ変更（相手はそのまま）
  await db
    .collection(CL_MESSAGE)
    .doc(uid)
    .collection(SCL_TALK_ROOM)
    .doc(roomId)
    .update({
      roomName: roomName,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

/*
 * (3) 直近のメッセージリスト（グループごと）を取得する
 */
export const getMessageHistory = async (uid: string) => {
  console.log('getMessageHistory>> called postId = ', uid);

  try {
    const snapshot = await db
      .collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_HISTORY)
      .orderBy('createdAt', 'desc')
      .get();
    const history = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_TALK_HISTORY)
    );
    console.log('getMessageHistory = ', history);
    return history;
  } catch (err) {
    console.log('getMessageHistory>> ', err);
    return [];
  }
};

/*
 * (4) ルームリストを取得する
 */
export const getMessageRoomList = async (uid: string) => {
  console.log('getMessageRoomList>> called postId = ', uid);
  try {
    const snapshot = await db.collection(CL_MESSAGE).doc(uid).get();
    const roomList = snapshot.data() as XROSS_MESSAGE;
    return roomList;
  } catch (err) {
    console.log('getMessageRoomList>> ', err);
    return null;
  }
};

/*
 * (5) ルームの情報を取得する
 */
export const getMessageRoomInfo = async (uid: string, roomId: string) => {
  console.log(
    'getMessageRoomInfo>> called postId = ',
    uid,
    ' roomId = ',
    roomId
  );
  try {
    const snapshot = await db
      .collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .get();
    const roomInfo = snapshot.data() as XROSS_TALK_ROOM;
    return roomInfo;
  } catch (err) {
    console.log('getMessageRoomList>> ', err);
    return null;
  }
};

/*
 * (6) ルーム内のメッセージリストを取得する
 */
export const getMessageList = async (uid: string, roomId: string) => {
  console.log('getMessageList>> called ' + uid, ' roomId ', roomId);
  try {
    const snapshot = await db
      .collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .orderBy('createdAt', 'desc')
      .get();
    const MessageList = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_TALK_HISTORY)
    );

    return MessageList;
  } catch (err) {
    console.log('getMessageList>> ', err);
    return [];
  }
};

/*
 * (7) ルームにメッセージを投稿する
 */
export const setMessage = async (
  uid: string,
  roomId: string,
  MESSAGE: XROSS_TALK
) => {
  console.log('setMessage>> called ' + uid, ' roomId ', roomId);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // ルームIDから送信先を取得する
  const roomInfo = await getMessageRoomInfo(uid, roomId);
  const person = roomInfo?.person;
  if (!person) return;
  let documentID;

  // オーナのトークルーム
  try {
    const ref = db
      .collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc();
    documentID = ref.id;
    console.log('+++++++++ documentID ++++++++++ ', documentID);
    await ref.set({
      id: documentID,
      text: MESSAGE.text,
      image: MESSAGE.image,
      stamp: MESSAGE.stamp,
      fromUid: 'me',
      userName: MESSAGE.sendUserName,
      avatar: MESSAGE.sendAvatar,
      createdAt: timestamp,
      lastRefreshAt: timestamp,
      deleteFlag: false,
    });
  } catch (err) {
    console.log(`Error 1: ${JSON.stringify(err)}`);
    console.log('documentID ', documentID);
    console.log('MESSAGE.text ', MESSAGE.text);
    console.log('MESSAGE.image ', MESSAGE.image);
    console.log('MESSAGE.stamp ', MESSAGE.stamp);
    console.log('MESSAGE.recvUserName ', MESSAGE.recvUserName);
    console.log('MESSAGE.recvAvatar ', MESSAGE.recvAvatar);
  }

  // 相手のトークルーム
  try {
    db.collection(CL_MESSAGE)
      .doc(person)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc(documentID)
      .set({
        id: documentID,
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        fromUid: uid,
        userName: MESSAGE.sendUserName,
        avatar: MESSAGE.sendAvatar,
        createdAt: timestamp,
        lastRefreshAt: timestamp,
        deleteFag: false,
      });
  } catch (err) {
    console.log(`Error 2: ${JSON.stringify(err)}`);
    console.log('documentID ', documentID);
    console.log('MESSAGE.text ', MESSAGE.text);
    console.log('MESSAGE.image ', MESSAGE.image);
    console.log('MESSAGE.stamp ', MESSAGE.stamp);
    console.log('MESSAGE.sendUserName ', MESSAGE.sendUserName);
    console.log('MESSAGE.sendAvatar ', MESSAGE.sendAvatar);
  }
  // オーナのヒストリー
  try {
    db.collection(CL_MESSAGE).doc(uid).collection(SCL_HISTORY).doc(roomId).set({
      roomId: roomId,
      text: MESSAGE.text,
      image: MESSAGE.image,
      stamp: MESSAGE.stamp,
      fromUid: 'me',
      userName: MESSAGE.recvUserName, // 送信先の名前
      avatar: MESSAGE.recvAvatar, // 送信先のアバター
      isGroup: false,
      createdAt: timestamp,
      lastRefreshAt: timestamp,
      deleteFag: false,
    });
  } catch (err) {
    console.log(`Error 3: ${JSON.stringify(err)}`);
    console.log('roomId ', roomId);
    console.log('documentID ', documentID);
    console.log('MESSAGE.text ', MESSAGE.text);
    console.log('MESSAGE.image ', MESSAGE.image);
    console.log('MESSAGE.stamp ', MESSAGE.stamp);
    console.log('MESSAGE.recvUserName ', MESSAGE.recvUserName);
    console.log('MESSAGE.recvAvatar ', MESSAGE.recvAvatar);
  }

  // 相手のヒストリー
  try {
    db.collection(CL_MESSAGE)
      .doc(person)
      .collection(SCL_HISTORY)
      .doc(roomId)
      .set({
        roomId: roomId,
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        fromUid: uid, // 送信者のUID
        userName: MESSAGE.sendUserName, // 送信者の名前
        avatar: MESSAGE.sendAvatar, // 送信者のアバター
        isGroup: false,
        createdAt: timestamp,
        lastRefreshAt: timestamp,
        deleteFag: false,
      });
  } catch (err) {
    console.log(`Error 4: ${JSON.stringify(err)}`);
    console.log('roomId ', roomId);
    console.log('documentID ', documentID);
    console.log('MESSAGE.text ', MESSAGE.text);
    console.log('MESSAGE.image ', MESSAGE.image);
    console.log('MESSAGE.stamp ', MESSAGE.stamp);
    console.log('MESSAGE.sendUserName ', MESSAGE.sendUserName);
    console.log('MESSAGE.sendAvatar ', MESSAGE.sendAvatar);
  }
};

/*
 * (8) メッセージを削除する
 */
export const deleteMessage = async (
  uid: string,
  roomId: string,
  messageId: string
) => {
  console.log(
    'deleteMessage>> called ' + uid,
    ' roomId ',
    roomId,
    ' messageId ',
    messageId
  );
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // ルームIDから送信先を取得する
  const roomInfo = await getMessageRoomInfo(uid, roomId);
  const person = roomInfo?.person;
  if (!person) return;

  // オーナのトークルーム
  try {
    db.collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc(messageId)
      .update({
        lastRefreshAt: timestamp,
        deleteFag: true,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
  // 相手のトークルーム
  try {
    db.collection(CL_MESSAGE)
      .doc(person)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc(messageId)
      .update({
        lastRefreshAt: timestamp,
        deleteFag: true,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
  //
  // ヒストリーも編集する必要がある・・・
  //
};

/*
 * (9) メッセージを編集する
 */
export const editMessage = async (
  uid: string,
  roomId: string,
  messageId: string,
  MESSAGE: XROSS_TALK
) => {
  console.log(
    'editMessage>> called ' + uid,
    ' roomId ',
    roomId,
    ' messageId ',
    messageId
  );

  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // ルームIDから送信先を取得する
  const roomInfo = await getMessageRoomInfo(uid, roomId);
  const person = roomInfo?.person;
  if (!person) return;

  // オーナのトークルーム
  try {
    db.collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc(messageId)
      .update({
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        lastRefreshAt: timestamp,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
  // 相手のトークルーム
  try {
    db.collection(CL_MESSAGE)
      .doc(person)
      .collection(SCL_TALK_ROOM)
      .doc(roomId)
      .collection(SSCL_TALK)
      .doc(messageId)
      .update({
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        lastRefreshAt: timestamp,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }

  // オーナのヒストリー
  try {
    db.collection(CL_MESSAGE)
      .doc(uid)
      .collection(SCL_HISTORY)
      .doc(roomId)
      .update({
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        lastRefreshAt: timestamp,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
  // 相手のヒストリー
  try {
    db.collection(CL_MESSAGE)
      .doc(person)
      .collection(SCL_HISTORY)
      .doc(roomId)
      .update({
        text: MESSAGE.text,
        image: MESSAGE.image,
        stamp: MESSAGE.stamp,
        lastRefreshAt: timestamp,
      });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};
