import React from 'react';
import { Marker } from '@react-google-maps/api';

interface PROPS {
  userName: string;
  avatar: string;
  latitude: string;
  longitude: string;
  locationLabel?: string;
}

const ShowMarker: React.FC<PROPS> = (props) => {
  let position = {
    lat: Number(props.latitude),
    lng: Number(props.longitude),
  };
  console.log('Avatar = ', props.avatar);

  return (
    <>
      <Marker
        position={position}
        icon={{
          fillColor: '#FFFFFF', //塗り潰し色 cssで色指定と同じ
          fillOpacity: 1.0, //塗り潰し透過率
          path: google.maps.SymbolPath.CIRCLE, //円を指定
          scale: 38, //円のサイズ
          strokeColor: 'green', //枠の色
          strokeWeight: 3.0, //枠の太さ
        }}
      />

      <Marker
        position={position}
        label={{
          color: 'gray',
          fontSize: '28px',
          text: props.userName,
        }}
        icon={{
          url: props.avatar ? props.avatar : '',
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(25, 25),
          scaledSize: new window.google.maps.Size(50, 50),
          // ここでアイコン表示の設定ができます。
        }}
      />
    </>
  );
};

export default ShowMarker;
