import React, { useState, useEffect } from 'react';
import styles from './Post.module.css';
import { db } from '../firebase';
import firebase from 'firebase/app';

import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useHistory } from 'react-router';

// xross interfaces
import { XROSS_USER_PROFILE, XROSS_POST } from '../common/interface';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const PostList: React.FC<XROSS_POST> = (props) => {
  const history = useHistory();
  const handleClick = (id: string) => {
    console.log('PostList ->' + id);
    history.push('/PostDetail/' + id);
  };

  return (
    <TableRow key={props.id} hover onClick={(event) => handleClick(props.id)}>
      <TableCell component="th" scope="row">
        {props.id}
      </TableCell>
      <TableCell>
        {new Date(props.createdAt?.toDate()).toLocaleString()}
      </TableCell>
      {/*    <TableCell>
        <Avatar src="" />
      </TableCell> */}
      {/* <TableCell>{props.username}</TableCell> */}
      <TableCell>{props.uid}</TableCell>
      <TableCell>{props.text}</TableCell>
      <TableCell>
        {props.contents && (
          <div className={styles.post_tweetImage_small}>
            <img src={props.contents[0]} alt="tweet" />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PostList;
