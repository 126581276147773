import React, { useState, useEffect } from 'react';

// View
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';

// xross interfaces
import { SIMPLE_LIST, XROSS_PUBLIC_USER_INFO } from '../common/interface';
import { getPublicUserInfo } from '../common/firebase/user';

const ListView: React.FC<SIMPLE_LIST> = (props) => {
  const [PublicUserInfo, setPublicUserInfo] =
    useState<XROSS_PUBLIC_USER_INFO>();
  useEffect(() => {
    userNum();
  }, []);

  const userNum = async () => {
    const user = await getPublicUserInfo(props.uid);
    if (user != null) {
      setPublicUserInfo(user);
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">{props.id}</TableCell>
              <TableCell align="center">
                <Avatar src={PublicUserInfo?.xrossAvatarURL} />
              </TableCell>
              <TableCell align="center">{props.uid}</TableCell>
              {/* <TableCell align="center">{props.postId}</TableCell> */}
              <TableCell align="center">{props.text}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ListView;
