import React, { useState, useEffect } from 'react';
//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

// View
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// xross interfaces
import { XROSS_POST, XROSS_COMMENT } from '../common/interface';
// xross common function
import { getCommentList } from '../common/firebase/post';
// view compornent
import ListView from './ListView';

const PostCommentView: React.FC<XROSS_POST> = (postDataSet) => {
  const postId = postDataSet.id;
  console.log('postId=' + postId);

  const [comments, setComments] = useState<XROSS_COMMENT[]>([]);

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const commentList = await getCommentList(postId);
    setComments(commentList);
  };

  return (
    <>
      <h3>返信コメント（{comments.length}件）</h3>
      <div>postId={postId}</div>
      <TableContainer component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Avater</TableCell>
            <TableCell>userID</TableCell>
            {/* <TableCell>postID</TableCell> */}
            <TableCell>text</TableCell>
          </TableRow>
        </TableHead>
      </TableContainer>
      <div>
        {comments[0]?.id && ( // 存在するときのみ
          <>
            {comments.map((doc) => (
              <ListView
                key={doc.id}
                id={doc.id}
                uid={doc.uid}
                postId={postId}
                text={doc.comment}
                createdAt={doc.createdAt}
                lastRefreshAt={doc.lastRefreshAt}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PostCommentView;
