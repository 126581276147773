import React from 'react';

/*
 * PostShereViewError
 */

const PostShereViewError: React.FC = () => {
  console.log('PostShereViewError ');

  return (
    <>
      <div>
        <h1>error</h1>
      </div>
    </>
  );
};

export default PostShereViewError;
