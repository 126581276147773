import React from 'react';
//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

// View
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// xross interfaces
import { XROSS_POST } from '../common/interface';

const postDetailEdit: React.FC<XROSS_POST> = (postDataSet) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          {/*
          <TableRow>
            <Avatar
              sx={{ width: 56, height: 56 }}
              src={postDataSet.xrossAvatarURL}
            />
          </TableRow> */}

          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>postId</TableCell>
            <TableCell>{postDataSet.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>text</TableCell>
            <TableCell>{postDataSet.text}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>hashtag</TableCell>
            <TableCell>{postDataSet.hashtag}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>contents</TableCell>
            <TableCell>{postDataSet.contents}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>gLocation</TableCell>
            <TableCell>
              <div>{postDataSet.gLocation.latitude}</div>
              <div>{postDataSet.gLocation.longitude}</div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>displayGLocationLabel</TableCell>
            <TableCell>{postDataSet.displayGLocationLabel}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>disclosureRange</TableCell>
            <TableCell>{postDataSet.disclosureRange}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>postStatus</TableCell>
            <TableCell>{postDataSet.postStatus}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>createdAt</TableCell>
            <TableCell>{postDataSet.createdAt}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>lastRefreshAt</TableCell>
            <TableCell>{postDataSet.lastRefreshAt}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default postDetailEdit;
